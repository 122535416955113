export const Teamdata=[
    {
        Id:0,
        FirstName:"Claudia",
        LastName:"Muench",
        Title:'CEO',
        urlLinkidin:'https://www.linkedin.com/in/claudia-muench-64580a6/',
        urlTeam:"./Images/Team/OptimizeImage/_Final_46.jpg",
        BioBool:true,
        Bios:'Claudia is a British and German national. She holds an MBA and is a chartered accountant (CIMA) with 10 years+ finance experience in the UK and France working for DPDgroup Ltd. within the logistics & parcel distribution sector. She has extensive experience within investment planning, lease finance, franchise models and financial and management accounting and most recently served as CFO at Inyenyeri'
    },
    /*{
        Id:1,
        FirstName:"John",
        LastName:"Butare",
        Title:'Deputy CEO',
        urlLinkidin:'https://www.linkedin.com/in/john-butare-5788487b/',
        urlTeam:"./Images/Team/_Final_67.jpg",
        BioBool:true,
        Bios:'John has over 20 years of experience in tax administration and advisory. He holds an MBA from Mount Kenya University and there after worked for the Rwandan Revenue Authority for 13 years. He focused on the private sector for the last 7 years, setting up his own practice and specialising in institutional planning, fiscal policy and strategy, account management and training. He served as a consultant to Inyenyeri for the last 3 years focusing on Government relations.'
    },
    {
        Id:2,
        FirstName:"Faridi",
        LastName:"Karume",
        Title:'CMO',
        urlLinkidin:'',
        urlTeam:"./Images/Team/_Final_4.jpg",
        BioBool:true,
        Bios:'Faridi is a Rwandan national who most recently served as a member of the Board of Directors at Inyenyeri where he contributed since its very beginings. Previously, he worked for Malteser Intl and Nutri Aid. He plays a main role in building partnership models with local government organizations and has an excellent understanding of government strategies, policies and laws. He impresses with his natural communication and negotiation skills and his strategic view on business and policy matters.'
    },*/
    {
        Id:1,
        FirstName:"Jan",
        LastName:"de Graaf",
        Title:'COO',
        urlLinkidin:'https://www.linkedin.com/in/jan-de-graaf-b0b6bb49/',
        urlTeam:"./Images/Team/OptimizeImage/_Final_57.jpg",
        BioBool:true,
        Bios:"Jan has been working in the clean cooking sector in East Africa for the past 9 years. First with BioLite Inc., for which he helped to set up its subsidiaries in Uganda and Kenya. After that, he led the day-to-day management of Inyenyeri as the Senior VP Operations. More recently Jan has worked in the solar sector as a World Bank consultant. Jan holds a master’s degree in public policy from the National University of Singapore. He was born in Rwanda, grew up in the Netherlands and lived in Canada, the US, Singapore and China."
    },
    {
        Id:2,
        FirstName:"Innocent",
        LastName:"Nsekeyukunze",
        Title:'Director of Production',
        urlLinkidin:'https://www.linkedin.com/in/innocent-nsekeyukunze-4b401612b/',
        urlTeam:"./Images/Team/OptimizeImage/_Final_52.jpg",
        BioBool:true,
        Bios:'Innocent is a Rwandan national with many years of experience in the clean cooking sector, working for Delagua Group and most recently for Inyenyeri in varying roles and capacities. He is a true multi-talent. He worked for Inyenyeri for over 4 years and held the roles of Rural Operations Manager, Biomass Sourcing Manager and most recently as Plant Manager, overseeing the factory team. Innocent has a proven track record of managing large teams and finding pragmatic and sustainable solutions to operational problems.'
    }    
]

export const BoardTeam=[
    {
        Id:0,
        FirstName:"Claude",
        LastName:"Mansell",
        Title:'Board Chair',
        urlLinkidin:'https://www.linkedin.com/in/claudemansell/',
        urlTeam:"./Images/Team/OptimizeImage/Claude.jpg",
        BioBool:true,
        Bios:'Claude’s worked for 30 years in management consulting (Capgemini) and IT-related businesses (HP) and over 8 years in Rwanda as investor and turn-around manager in a food processing company, and GM of for-profit, social benefit and not-for-profit (Clinton Development Initiative) organizations. His last position was CEO of Inyenyeri, a clean cooking company. He is also a member of the Board of Directors and Board of Advisors of various companies in Rwanda.'
    },
    {
        Id:1,
        FirstName:"Charity",
        LastName:"Kagenza",
        Title:'Board Member',
        urlLinkidin:'https://www.linkedin.com/in/charity-kagenza-648a241a/',
        urlTeam:"./Images/Team/Charity.jpg",
        BioBool:true,
        Bios:'Charity is a senior finance professional with over 10 years in commercial banking and currently works as Executive Director at Matha Capital Rwanda, a financial advisory firm for Sovereigns, State Owned Enterprises and Corporates. She brings strong finance and stakeholder engagement skills to the board.'
    },
    {
        Id:2,
        FirstName:"Eric",
        LastName:"Millinger",
        Title:'Board Member',
        urlLinkidin:'https://www.linkedin.com/in/ericmillinger/',
        urlTeam:"./Images/Team/Denis2.jpg",
        BioBool:true,
        Bios:"Eric is the founder and CEO of Redstone Manufacturing. Redstone Manufacturing is a US-based metal casting and metal manufacturing company with locations in India, Vietnam, Mexico, and China. Eric has also previously founded a financial consultancy firm and brings financial expertise and a strong entrepreneurial spirit to the BioMassters' Board. Eric holds a BS in Environmental Engineering from the University of Colorado. His interest in clean cooking began a decade ago, when he worked for Inyenyeri in 2011-2012."
    },
    {
        Id:3,
        FirstName:"Wanji",
        LastName:"Ng'ang'a",
        Title:'Board Member',
        urlLinkidin:'https://www.linkedin.com/in/wanji-ng-ang-a-332385a3/?originalSubdomain=ke',
        urlTeam:"./Images/Team/Wanji.png",
        BioBool:true,
        Bios:"Wanji joins the BioMassters Board with ample experience in business, finance and investing. After hands-on experience in the solar industry, cooking sector and finance sector at GOGLA and Shell foundation, she now represents our investor Acumen as Director of Investing for East Africa. Her experience, network and business acumen in the region greatly enriches the skillset of the BioMassters Board."
    }
]
